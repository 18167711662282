<template lang="pug">
IonButton(
    color="transparent"
    size="large"
    expand="block"
    style="height: 65px; --border-radius: 0; --padding-start: 0; --padding-end: 0"
)
    IonRow(
        style="width: 100%"
        class="custom-row"
    )
        IonCol(
            :size="textColSize"
            class="text-col pt-3 pb-3 overflow-hidden d-flex align-center"
            :class="reversed ? 'radius-left order-1' : 'radius-right'"
            style="min-height: 65px"
            :style="computedTextColStyle"
            class="ion-activatable ripple-parent"
        )
            ion-ripple-effect
            slot(
                name="text"
            )

        IonCol(
            :size="checkColSize"
            class="button-col overflow-hidden justify-center ion-no-padding d-flex align-center justify-center"
            :class="reversed ? 'radius-right' : 'radius-left'"
        )
            slot(
                name="check"
            )
</template>

<script>
import { computed } from "vue"
import { chevronForwardCircle } from "ionicons/icons"
export default {
    props: {
        reversed: {
            type: Boolean
        },
        textColSize: {
            type: String,
            default: "10"
        },
        checkColSize: {
            type: String,
            default: "2"
        },
        textColBackgroundColor: {
            type: String
        }
    },

    setup(props) {
        const computedTextColStyle = computed(() => {
            return `
                background-color: ${props.textColBackgroundColor}
            `
        })
        chevronForwardCircle
        return { computedTextColStyle, chevronForwardCircle }
    }
}
</script>

<style lang="sass">
.custom-row
    .check-col
        ion-button
            margin: 0

</style>
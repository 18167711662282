<template lang="pug">
div(
    class="order-inventory-report"
    v-if="!!order"
)
    //- style="background-color: var(--ion-color-quaternary)"
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- boxes
    Container
        Spacer(
            small
        )
        IonRow(class="receding")
            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="primary"
                )
                    h3(class="with-margin") Kunde
                Box(
                    style="background-color: var(--ion-color-quaternary)"
                )
                    IonText(
                        color="primary"
                    )
                        div(class="mb-2") Frau Karin Weimann
                        div Wielandstrasse 13
                        div 70130 Stuttgart

            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="primary"
                )
                    h3(class="with-margin") Rapportdaten
                Box(
                    style="background-color: var(--ion-color-quaternary)"
                )
                    IonText(
                        color="primary"
                    )
                        div(class="mb-2") Monteur:
                        div Datum:

        Spacer(
            small
        )

    //- panels
    div
        //- description

        CustomCheckButton(
            textColBackgroundColor="var(--ion-color-accent)"
            style="margin-bottom: 2px"
            @click="descriptionModal = true"
        )
            template(
                v-slot:text
            )
                h3(
                    class="ion-padding-start"
                    style="color: white"
                ) Beschreibung
            template(
                v-slot:check
            )
                CheckableButton(
                    :checked="descriptionAccepted"
                    @click="descriptionModal = true"
                    color="quaternary"
                )

        //- CustomRow(
        //-     textColBackgroundColor="var(--ion-color-accent)"
        //-     style="margin-bottom: 2px"
        //- )
        //-     template(
        //-         v-slot:button
        //-     )
        //-         CheckableButton(
        //-             :checked="descriptionAccepted"
        //-             @click="descriptionModal = true"
        //-             color="quaternary"
        //-         )

        //-     template(
        //-         v-slot:text
        //-     )
        //-         h3(class="ion-padding-start") Beschreibung

        //- images

        CustomCheckButton(
            textColBackgroundColor="var(--ion-color-accent)"
            style="margin-bottom: 2px"
            @click="imagesModal = true"
        )
            template(
                v-slot:text
            )
                h3(
                    class="ion-padding-start"
                    style="color: white"
                ) Bilder
            template(
                v-slot:check
            )
                CheckableButton(
                    :checked="imagesAccepted"
                    @click="imagesModal = true"
                    color="quaternary"
                )

        //- CustomRow(
        //-     textColBackgroundColor="var(--ion-color-accent)"
        //-     style="margin-bottom: 2px"
        //- )
        //-     template(
        //-         v-slot:button
        //-     )
        //-         CheckableButton(
        //-             :checked="imagesAccepted"
        //-             @click="imagesModal = true"
        //-             color="quaternary"
        //-         )

        //-     template(
        //-         v-slot:text
        //-     )
        //-         h3(class="ion-padding-start") Bilder

        //- videos
        CustomCheckButton(
            textColBackgroundColor="var(--ion-color-accent)"
            style="margin-bottom: 2px"
            @click="videosModal = true"
        )
            template(
                v-slot:text
            )
                h3(
                    class="ion-padding-start"
                    style="color: white"
                ) Videos
            template(
                v-slot:check
            )
                CheckableButton(
                    :checked="videosAccepted"
                    @click="videosModal = true"
                    color="quaternary"
                )

        //- CustomRow(
        //-     textColBackgroundColor="var(--ion-color-accent)"
        //- )
        //-     template(
        //-         v-slot:button
        //-     )
        //-         CheckableButton(
        //-             :checked="videosAccepted"
        //-             @click="videosModal = true"
        //-             color="quaternary"
        //-         )

        //-     template(
        //-         v-slot:text
        //-     )
        //-         h3(class="ion-padding-start") Videos

    //- signature
    Container
        Spacer
        IonText(
            color="primary"
        ) Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.

        Spacer
        IonText(
            color="primary"
        )
            h3 Unterschrift Kunde
        Spacer(
            small
        )
        SignatureModule(
            v-if="$route.name == 'order-inventory-report'"
            @updateSignatureExists="setSignatureExists"
        )
        Spacer(
            small
        )

    //- buttons
    div(class="buttons-height-1 mt-4")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding")
        //-             IonCol(
        //-                 size="6"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="$router.push({ name: 'order-inventory', params: { id: order.id } })"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Abbruch

        //-             IonCol(
        //-                 size="6"
        //-             )
        //-                 IonButton(
        //-                     :disabled="!nextStepAllowed"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="addInventoryToDone(order.id), $router.push({ name: 'order-3', params: { id: order.id } })"
        //-                     :color="!nextStepAllowed ? 'secondary' : 'secondary'"
        //-                     :style="!nextStepAllowed ? { opacity: '0.3' } : {}"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Auftrag starten

CustomModal(
    v-model="descriptionModal"
)
    ConfirmModalContentWithSlot(
        @confirm="acceptDescription"
        @decline="declineDescription"
        questionText="Ich habe die Beschreibung gelesen und akzeptiert"
        v-if="!!order"
    )
        Spacer
        IonText(
            color="white"
        ) {{ order.remarks }}

        div(
            v-if="!order.remarks"
            style="height: 300px; color: white"
            class="text-center d-flex align-center justify-center"
        )
            h2 Keine Anmerkungen

CustomModal(
    v-model="imagesModal"
)
    ConfirmModalContentWithSlot(
        @confirm="acceptImages"
        @decline="declineImages"
        questionText="Ich habe die Bilder gesehen und akzeptiert"
    )
        Spacer
        IonRow(class="receding")
            IonCol(
                size="6"
                v-for="(image, index) in order.images"
                :key="index"
            )
                img(
                    :src="image.thumb"
                    style="width: 100%; height: 130px; object-fit: cover"
                    class="radius"
                )

        div(
            v-if="!order.images?.length"
            style="height: 300px; color: white"
            class="text-center d-flex align-center justify-center"
        )
            h2 Keine Bilder

CustomModal(
    v-model="videosModal"
)
    ConfirmModalContentWithSlot(
        @confirm="acceptVideos"
        @decline="declineVideos"
        questionText="Ich habe die Aufnahmen gesehen und akzeptiert"
    )
        Spacer
        IonRow(class="receding")
            IonCol(
                size="12"
                v-for="(video, index) in order.videos"
                :key="index"
            )
                img(
                    :src="video.thumb"
                    style="width: 100%; height: 260px; object-fit: cover"
                    class="radius"
                )

        div(
            v-if="!order.videos?.length"
            style="height: 300px; color: white"
            class="text-center d-flex align-center justify-center"
        )
            h2 Keine Aufnahmen
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import OrderName from "@/components/molecules/OrderName"
import CheckableButton from "@/components/molecules/CheckableButton"
import CustomRow from "@/components/molecules/CustomRow"
import CustomCheckButton from "@/components/molecules/CustomCheckButton"
import SignatureModule from "@/components/molecules/SignatureModule"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContentWithSlot from "@/components/molecules/modals/ConfirmModalContentWithSlot"
import Box from "@/components/molecules/Box"
import CustomCheckableIcon from "@/components/molecules/CustomCheckableIcon"

import { chevronForwardCircle } from "ionicons/icons"
import { ref, computed, watch } from "vue"
import { useBtnController } from "@/composables/buttons-controller"
export default {
    components: {
        TopBar,
        OrderName,
        CheckableButton,
        Box,
        CustomRow,
        CustomCheckButton,
        SignatureModule,
        CustomModal,
        ConfirmModalContentWithSlot,
        CustomCheckableIcon
    },

    props: {
        order: {
            required: true
        }
    },

    setup() {
        chevronForwardCircle
        const tab = ref("video")

        // description modal
        const descriptionModal = ref(false)
        const descriptionAccepted = ref(false)
        function acceptDescription() {
            descriptionAccepted.value = true
            descriptionModal.value = false
        }
        function declineDescription() {
            descriptionAccepted.value = false
            descriptionModal.value = false
        }

        // videos modal
        const videosModal = ref(false)
        const videosAccepted = ref(false)
        function acceptVideos() {
            videosAccepted.value = true
            videosModal.value = false
        }
        function declineVideos() {
            videosAccepted.value = false
            videosModal.value = false
        }

        // images modal
        const imagesModal = ref(false)
        const imagesAccepted = ref(false)
        function acceptImages() {
            imagesAccepted.value = true
            imagesModal.value = false
        }
        function declineImages() {
            imagesAccepted.value = false
            imagesModal.value = false
        }

        // temp
        const orderVideos = ref([
            {
                name: "v1-210120-11.30",
                id: "1",
                thumb: require("@/assets/temp/1.jpeg")
            },
            {
                name: "v2-210120-11.37",
                id: "2",
                thumb: require("@/assets/temp/2.jpeg")
            }
        ])

        // signature
        const signatureExists = ref()
        function setSignatureExists(val) {
            signatureExists.value = val
        }

        // btn controller
        const nextStepAllowed = computed(() => {
            return (
                descriptionAccepted.value &&
                imagesAccepted.value &&
                videosAccepted.value &&
                signatureExists.value
            )
        })

        const { setOrderInventoryReportNextBtn } = useBtnController()

        watch(
            () => nextStepAllowed.value,
            (val) => setOrderInventoryReportNextBtn(val),
            { immediate: true }
        )

        const orderImages = ref([
            {
                name: "i1-210120-11.30",
                id: "3",
                thumb: require("@/assets/temp/3.jpeg")
            },
            {
                name: "i2-210120-11.37",
                id: "4",
                thumb: require("@/assets/temp/4.jpeg")
            },
            {
                name: "i3-210120-11.37",
                id: "5",
                thumb: require("@/assets/temp/5.jpeg")
            }
        ])

        // end temp

        return {
            tab,
            chevronForwardCircle,
            descriptionModal,
            acceptDescription,
            declineDescription,
            descriptionAccepted,
            videosModal,
            videosAccepted,
            acceptVideos,
            declineVideos,
            imagesModal,
            imagesAccepted,
            acceptImages,
            declineImages,
            orderImages,
            orderVideos,
            nextStepAllowed,
            setSignatureExists
        }
    }
}
</script>
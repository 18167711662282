<template lang="pug">
BaseLayout(
    light
)
    OrderInventoryReport(
        :order="currentOrder"
    )
</template>

<script>
import OrderInventoryReport from "@/components/sections/order-processing/OrderInventoryReport"

import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        OrderInventoryReport
    },

    setup() {
        const { currentOrder } = useUtilities()
        return { currentOrder }
    }
}
</script>